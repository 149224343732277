import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, Link } from "react-router-dom";

class Patnership extends Component {

    render() {
        return (
            <div>

                <Helmet>
                    <title>Partnerships - DevsyTech IT Solutions & Services Company in Kenya</title>
                    <meta name="description" content="Explore partnership opportunities with Devsy Tech. Together, we can drive innovation and deliver cutting-edge IT solutions." />
                    <meta name="keywords" content="partnerships, IT solutions, technology collaboration, strategic partners, Devsy Tech" />
                    <link rel="canonical" href="https://devsytech.com/partnerships"></link>
                    <meta name="robots" content="index, follow" />
                    <meta http-equiv="content-language" content="en" />
                    <meta name="author" content="Devsy Tech" />
                    <meta name="copyright" content="Copyright © 2024 Devsy Tech. All rights reserved." />
                    <meta property="og:title" content="Partnerships | Devsy Tech" />
                    <meta property="og:description" content="Explore partnership opportunities with Devsy Tech. Together, we can drive innovation and deliver cutting-edge IT solutions." />
                    <meta property="og:image" content="https://devsytech.com/logo.png" />
                    <meta property="og:url" content="https://www.devsytech.com/partnerships" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Partnerships | Devsy Tech" />
                    <meta name="twitter:description" content="Explore partnership opportunities with Devsy Tech. Together, we can drive innovation and deliver cutting-edge IT solutions." />
                    <meta name="twitter:image" content="https://devsytech.com/logo.png" />
                </Helmet>

                <section class="breadcrumb-area banner-2">
                    <div class="text-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 v-center">
                                    <div class="bread-inner">
                                        <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="#">Partnership</Link></li>
                                            </ul>
                                        </div>
                                        <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
                                            <h2>Partnership</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-agencys pad-tb block-1 dark-bg3" >
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <div className="common-heading text-l">
                                    <h2 className="mb20">Partner with Devsy Tech: Driving Innovation Together</h2>
                                    <p>At Devsy Tech, we believe that collaboration is key to unlocking the full potential of technology. Our partnerships are built on a foundation of mutual trust, shared vision, and a commitment to excellence. By partnering with us, you gain access to a wealth of expertise, innovative solutions, and a dedicated team passionate about delivering results.</p>
                                    <p>We offer a range of partnership opportunities designed to cater to different needs and objectives. Whether you're looking to enhance your technological capabilities, explore new markets, or co-create cutting-edge solutions, Devsy Tech is your ideal partner. Our collaborative approach ensures that every partnership is tailored to achieve specific goals and drive mutual success.</p>
                                    <p>Join us in our mission to push the boundaries of technology and deliver unparalleled value to our clients. Together, we can achieve great things.</p>
                                    <a href="#partnerships" className="btn-main bg-btn2 lnk mt30">Learn More About Our Partnerships <i className="fa fa-chevron-right fa-icon"></i><span className="circle"></span></a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="image-block mb0 m-mt30">
                                    <img src="images/partnership.gif" alt="Partnerships with Devsy Tech" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="cta-area bg-gradient5 pad-tb" id="partnerships">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="common-heading">
                                    <span>Let's work together</span>
                                    <h2>We Love to Listen to Your Partnership Ideas</h2>
                                    <a href="mailto:info@devsytech.com" class="btn-outline">Email Us for Partnership <i class="fa fa-envelope"></i></a>
                                    <p class="cta-call">Or call us now <a href="tel:+254745805901"><i class="fas fa-phone-alt"></i> +254 745805901</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="shape shape-a1"><img src="images/shape/shape-3.svg" alt="shape"/></div>
                    <div class="shape shape-a2"><img src="images/shape/shape-4.svg" alt="shape"/></div>
                    <div class="shape shape-a3"><img src="images/shape/shape-13.svg" alt="shape"/></div>
                    <div class="shape shape-a4"><img src="images/shape/shape-11.svg" alt="shape"/></div>
                </section>



            </div>
        );
    }
}

export default Patnership;
