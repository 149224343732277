import React from 'react';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import 'font-awesome/css/font-awesome.css';

const rootElement = document.getElementById('app-root');
createRoot(rootElement).render(
  <React.StrictMode>
    {/* Wrap your entire application with HelmetProvider */}
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
